<template>
  <div class="mb-6">
    <div class="d-flex ma-2 align-center">
      <h3 v-if="user.admin">{{ userName }}</h3>
      <h3 v-else>{{ prettyDate(start) }} to {{ prettyDate(end) }}</h3>
      <submitted v-if="user.admin && start" small :value="submitted"
                 document="freelance_invoice" :detail="`${start} to ${end}`" :target-user-id="userId">
      </submitted>
      <v-spacer></v-spacer>
      <file-download color="gray" small end-point="/freelance/invoice/export" :params="{ user_id:userId, start, end }"
                     @click="$refs.downloadHistory.fetch()">
        Download
      </file-download>
      <download-history ref="downloadHistory" v-if="user.admin && start" document="freelance_invoice"
                        :date="selectedDate" :detail="`${start} to ${end}`" :target-user-id="userId">
      </download-history>
    </div>

    <freelance-invoice :days="jobs" :user-slug="userSlug"></freelance-invoice>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DownloadHistory from '../../components/ui/DownloadHistory'
import Submitted from '../../components/ui/Submitted'
import FileDownload from '../../components/export/FileDownload'
import FreelanceInvoice from '../../components/freelance/FreelanceInvoice'

export default {
  name: 'FreelanceInvoiceContainer',
  components: { Submitted, DownloadHistory, FileDownload, FreelanceInvoice },
  props: {
    userName: String,
    userId: Number,
    userSlug: String,
    jobs: Array,
    submitted: Boolean,
    selectedDate: String,
    start: String,
    end: String
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style scoped>

</style>
