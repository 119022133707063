import { render, staticRenderFns } from "./FreelanceInvoice.vue?vue&type=template&id=fd9d46de&scoped=true&"
import script from "./FreelanceInvoice.vue?vue&type=script&lang=js&"
export * from "./FreelanceInvoice.vue?vue&type=script&lang=js&"
import style0 from "./FreelanceInvoice.vue?vue&type=style&index=0&id=fd9d46de&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd9d46de",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VIcon,VSimpleCheckbox,VSimpleTable})
