<template>
<page-wrapper>
  <v-row dense class="ml-1 mr-1">
    <v-select v-if="userInOffice"
              v-model="userID" :items="freelanceUsers"
              class="user-select" label="User">
    </v-select>
    <v-select v-model="query" :items="queryChoices" label="Query" class="time-window-select"></v-select>
    <v-select v-if="query !== 'job'"
              v-model="selectedDate" :items="dateOptions" :label="dateOptionLabel"
              class="week-select"
              @change="queryChanged">
    </v-select>
    <job-select v-else-if="query === 'job'" v-model="jobID" class="job-select">
    </job-select>
    <v-spacer></v-spacer>
  </v-row>
  <div v-if="shifts && shifts.length === 0" class="text-center ma-4 grey--text">
    <i>Nothing to display</i>
  </div>
  <template v-else-if="shifts">
    <div v-for="{ user_name, user_id, user_slug, jobs, submitted } in shifts" :key="user_name" class="mb-6">
      <freelance-invoice-container :user-id="user_id" :user-name="user_name" :user-slug="user_slug"
                                   :jobs="jobs" :submitted="submitted"
                                   :selected-date="selectedDate" :start="start" :end="end">
      </freelance-invoice-container>
    </div>
  </template>

</page-wrapper>
</template>

<script>
import PageWrapper from '../components/ui/PageWrapper'
import * as api from '../api'
import dayjs from 'dayjs'
import { mapGetters, mapState } from 'vuex'
import JobSelect from '../components/ui/JobSelect'
import FreelanceInvoiceContainer from '../components/freelance/FreelanceInvoiceContainer'

export default {
  name: 'FreelanceInvoices',
  components: { FreelanceInvoiceContainer, PageWrapper, JobSelect },
  data() {
    return {
      queryOptions: ['week', 'month', 'job'],
      query: 'week',
      dateOptions: [],
      selectedDate: null,
      jobID: null,
      shifts: [],
      userID: null,
      freelanceUsers: [],
      start: null,
      end: null
    }
  },
  watch: {
    async userID() {
      if (this.query === 'job') {
        if (this.jobID) {
          this.queryChanged(true)
        }
      } else {
        await this.refreshDateOptions()
        this.queryChanged(true)
      }
    },
    async query() {
      if (this.query === 'job') {
        this.shifts = null
      } else {
        await this.refreshDateOptions()
      }
    },
    async jobID() {
      this.queryChanged(true)
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['userInOffice']),
    queryChoices() {
      return this.queryOptions.map(value => ({ value, text: this.makeTitle(value) }))
    },
    dateOptionLabel() {
      if (this.query === 'week') return 'Week commencing'
      if (this.query === 'month') return 'Month'
      else return ''
    },
    userSlug() {
      if (this.userInOffice) {
        for (const user of this.freelanceUsers) {
          if (user.value === this.userID) {
            return this.slugify(user.text)
          }
        }
      } else {
        return this.user.slug
      }
      return ''
    },
    allUsers() {
      return this.userID === 0
    }
  },
  methods: {
    async refreshDateOptions() {
      if (this.query !== 'job') {
        const periods = await api.get('/user/logged-periods', { user_id: this.userID, period: this.query })
        const timeFormat = this.query === 'week' ? 'D MMMM YYYY' : 'MMMM YYYY'
        this.dateOptions = periods.map(value => ({ value, text: dayjs(value).format(timeFormat) }))
        if (this.dateOptions.length > 0) {
          if (!this.selectedDate) {
            this.selectedDate = this.dateOptions[0].value
            this.queryChanged()
          }
        } else {
          this.shifts = null
        }
      }
    },
    queryChanged(force = false) {
      if (this.selectedDate !== this.$route.query.date || force) {
        const query = { query_type: this.query }
        if (this.query === 'job') {
          query.job = this.jobID
        } else {
          query.date = this.selectedDate
        }
        if (this.userInOffice) {
          query.user = this.userID
        }
        this.$router.push({
          name: this.$route.name,
          query
        }).catch((e) => {})
      }
    },
    async fetch(date, user, job) {
      this.selectedDate = date || this.selectedDate
      this.userID = +(user || this.userID)
      this.jobID = +job

      if (!this.selectedDate && !this.jobID) {
        return
      }

      const params = { user_id: this.userID }

      if (this.query === 'job') {
        params.job_id = this.jobID
      } else {
        this.start = dayjs(this.selectedDate).startOf('day').format('YYYY-MM-DD HH:mm')
        let end = dayjs(this.selectedDate)
        if (this.query === 'week') {
          end = end.add(6, 'days')
        } else if (this.query === 'month') {
          end = end.endOf('month')
        }
        this.end = end.endOf('day').format('YYYY-MM-DD HH:mm')
        params.start = this.start
        params.end = this.end
      }
      this.shifts = await api.get('/freelance/shifts', params)
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { date, user, query_type, job } = to.query
    this.query = query_type
    await this.fetch(date, user, job)
    next()
  },
  async mounted() {
    const { date, user, query_type, job } = this.$route.query
    this.query = query_type || 'week'

    if (this.userInOffice) {
      const users = await api.get('/freelance/users')
      users.unshift({ id: 0, name: 'All' })
      this.freelanceUsers = users.map(user => ({ value: user.id, text: user.name }))
      if (user && (date || job)) {
        await this.fetch(date, user, job)
      } else {
        this.userID = 0
      }
    } else {
      this.userID = this.user.id
      if (date || job) {
        await this.fetch(date, user, job)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-input {
  margin-right: 1rem;
}

.user-select {
  max-width: 250px;
}

.time-window-select {
  max-width: 110px;
}

.week-select {
  max-width: 200px;
}

.job-select {
  max-width: 350px;
}

div.buttons {
  button {
    margin: 5px;
  }
}
</style>
