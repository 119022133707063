<template>
<v-simple-table class="freelance-invoice">
  <thead>
  <tr>
    <th>Day</th>
    <th>Date</th>
    <th>Project</th>
    <th>Job #</th>
    <th>Category</th>
    <th>T x 1</th>
    <th>T x 1.5</th>
    <th>T x 2</th>
    <th>T x 3</th>
    <th>Early call</th>
    <th>TOC</th>
    <th>Travel</th>
    <th>Own car</th>
    <th>Mileage</th>
    <th>Extra</th>
    <th>Total</th>
    <th v-if="user.admin">Approved</th>
  </tr>
  </thead>
  <tbody>
  <tr v-for="shift in days" :key="`${shift.date}-${shift.job_number}-${shift.category}`"
      :class="{ unapproved: !shift.approved }">
    <td>{{ shift.day }}</td>
    <td>
      <router-link class="text-decoration-none"
                   :to="{ name: isCurrentUser ? 'calendar' : 'user-calendar',
                          params: { userSlug: userSlug },
                          query: { date: shift.date, entry_uid: shift.entry_uid} }">
        {{ shift.date | prettyDate }}
        <v-icon v-if="user.admin && shift.flagged" color="warning">mdi-flag</v-icon>
      </router-link>
    </td>
    <td>{{ shift.job_name }}</td>
    <td>{{ shift.job_number }}</td>
    <td>{{ shift.category }}</td>
    <td>{{ shift.t_1 }}</td>
    <td>{{ shift.t_1p5 }}</td>
    <td>{{ shift.t_2 }}</td>
    <td>{{ shift.t_3 }}</td>
    <td>{{ shift.early_call || '' }}</td>
    <td>{{ shift.toc || '' }}</td>
    <td>{{ shift.travel || '' }}</td>
    <td>{{ shift.own_car ? '£' : '' }}{{ shift.own_car || '' }}</td>
    <td>{{ shift.mileage ? '£' : '' }}{{ shift.mileage || '' }}</td>
    <td>{{ shift.extra_pay ? '£' : '' }}{{ shift.extra_pay || '' }}</td>
    <td>£{{ shift.total }}</td>
    <td v-if="user.admin">
      <v-simple-checkbox :value="approved[shift.uid]"
                         @input="approvedChanged(shift, $event)" :ripple="false">
      </v-simple-checkbox>
    </td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="5">Totals</td>
    <td>{{ sumOf('t_1') }}</td>
    <td>{{ sumOf('t_1p5') }}</td>
    <td>{{ sumOf('t_2') }}</td>
    <td>{{ sumOf('t_3') }}</td>
    <td>{{ sumOf('early_call') }}</td>
    <td>{{ sumOf('toc') }}</td>
    <td>{{ sumOf('travel') }}</td>
    <td>£{{ sumOf('own_car') }}</td>
    <td>£{{ sumOf('mileage') }}</td>
    <td>£{{ sumOf('extra_pay') }}</td>
    <td>£{{ sumOf('total') }}</td>
  </tr>
  </tfoot>
</v-simple-table>
</template>

<script>
import { mapState } from 'vuex'
import { PayrollMixin } from '../../mixins/PayrollMixin'

export default {
  name: 'FreelanceInvoice',
  mixins: [PayrollMixin],
  props: {
    userSlug: String
  },
  computed: {
    ...mapState(['user']),
    isCurrentUser() {
      return this.user.slug === this.userSlug
    }
  },
  methods: {
    sumOf(key) {
      let total = this.days.reduce((total, day) => total + (day[key] || 0), 0)
      total = Math.round(total * 100) / 100 // max 2 decimal places
      return total
    }
  },
  created() {
    this.syncApproved()
  }
}
</script>

<style scoped lang="scss">
tfoot {
  font-weight: bold;
}
tr.unapproved {
  td {
    color: #aaa;
  }
}
</style>
